import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { data } from './data'


const Content = styled.div`
  width: 100vw;
  position: absolute;
  bottom: 36px;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  background-color: lightgray; */

  div.visible-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    @media screen and (max-width: 600px) {
        position: fixed;
        bottom: 16px;
        right: 16px;
    }
    h1 {
      max-width: 400px;
      margin-left: 16px;
      font-size: 15px;
      line-height: 1.5;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 32px;
      height: 44px;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      background-color: white;
      text-align: center;


      @media screen and (max-width: 600px) {
        position: fixed;
        top: 75px;
      }
    }
    a {
      background: white;
      height: 54px;
      line-height: 60px;
      width: 54px;
      border-radius: 32px;
      text-align: center;
      vertical-align: middle;
      font-size: 24px;
    }
    div.url {

    }

    .close {
      width: 24px;
      height: 24px;
    }
  }

`;


const Placeholder = styled.div`
  width: 300px;
  height: 350px;
`;


const Space = styled.div`
  height: 16px;
`;

const Link = styled.a`
    text-decoration: none;
`;

function Card(props) {
  const uuid = props.uuid;
  const cardData = data.nodes.find(node => node.id === uuid);
  if (cardData) {
    return (
      <Content>
        <div className='visible-container'>
          {
            cardData.body &&
            <h1>
              {cardData.body}
            </h1>
          }

          {/* <IconButton
            className="close"
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton> */}
          {
            cardData.urls && cardData.urls.map((elem, index) =>
              <Link href={elem} target="_blank">↗</Link>
            )
          }
        </div>
        {/* {
          (cardData.body || cardData.urls) &&
          <div className='bottom'>
            {cardData.body}
            {cardData.body && cardData.urls && <Space />}

          </div>
        } */}
      </Content>
    );
  } else {
    return (
      <Placeholder />
    );
  }

}

export default Card;

